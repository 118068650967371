import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from '@remix-run/react';
import { isLocationHomepage } from '~/components/utils/location';
import { useLanguageParam } from '~/config/i18n';

export type PathList = { name: string; obfuscateHP: boolean; obfuscateElse: boolean }[];

const whoWeAre: PathList = [
  {
    name: 'coworkingAndAlternatives',
    obfuscateHP: false,
    obfuscateElse: true,
  },
  {
    name: 'blog',
    obfuscateHP: false,
    obfuscateElse: true,
  },
  {
    name: 'aboutUs',
    obfuscateHP: false,
    obfuscateElse: false,
  },
  {
    name: 'contactUs',
    obfuscateHP: true,
    obfuscateElse: true,
  },
];

const AboutUs = ({ showMobileFooter = true }: { showMobileFooter: boolean }) => {
  const location = useLocation();
  const lang = useLanguageParam();
  const { t } = useTranslation();

  const isHomepage = isLocationHomepage(location);

  return (
    <div key="aboutus">
      <NavLink
        className={`navbar-brand py-5 py-md-0 ${showMobileFooter ? '' : 'd-none d-md-flex'}`}
        key="fo-logo"
        to={`/${lang}`}
        data-turbo="false"
      >
        <img
          src="/media/logotype.svg"
          alt="Fiveoffices"
          key="logo-img"
          className={`fo-logo-full mb-4`}
        />
      </NavLink>
      <div className="d-flex flex-wrap row-gap-3 align-items-lg-flex-start justify-content-flex-start justify-content-lg-center">
        {whoWeAre.map((pathLink, index: number) => (
          <div
            key={`item-${index}`}
            className="d-flex align-items-center"
          >
            {(isHomepage && pathLink.obfuscateHP) || (!isHomepage && pathLink.obfuscateElse) ? (
              <button
                className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover p-0"
                key={`link-${index}`}
                onClick={() => {
                  window.open(t(`global.linkPaths.${pathLink.name}`), '_blank');
                }}
              >
                <span className="text-2 -regular align-items-center justify-content-center">
                  {t(`global.links.${pathLink.name}`)}
                </span>
              </button>
            ) : (
              <Link
                className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
                target="_blank"
                key={`link-${index}`}
                to={t(`global.linkPaths.${pathLink.name}`)}
              >
                <span className="text-2 -regular align-items-center justify-content-center">
                  {t(`global.links.${pathLink.name}`)}
                </span>
              </Link>
            )}

            {index !== whoWeAre.length - 1 && (
              <div
                key={`VLine-${index}`}
                className="fo-border-right-md border-grey-200 mx-3 align-self-stretch"
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
